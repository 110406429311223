export const websiteUrl = 'https://becomeunstuck.app';
export const backendUrl = import.meta.env.VITE_BACKEND_URL;
export const appStoreUrl = 'https://apps.apple.com/app/id1613810335';
export const playStoreUrl =
  'https://play.google.com/store/apps/details?id=app.becomeunstuck.unstuck';
export const signInEmailStorageKey = 'signInEmail';
export const rcPublicKeyAndroid = 'goog_wOWLHYONZopDHjWBozUTrBTETDZ';
export const rcPublicKeyApple = 'appl_OfRmLyROyFkYtmPAGjwYhPlXaKT';
export const stripeCustomerPortalUrl = import.meta.env.PROD
  ? 'https://billing.stripe.com/p/login/bIY8zYfeY2qhc3C144'
  : 'https://billing.stripe.com/p/login/test_6oEcOB7lMdTxd203cc';
export const instagramUrl = 'https://www.instagram.com/becomeunstuck.app';
export const redditUrl = 'https://www.reddit.com/r/becomeunstuck';
export const facebookUrl = 'https://www.facebook.com/becomeunstuckapp';
export const emailContactUrl = 'mailto:contact@becomeunstuck.app';
export const rcOfferingIds = {
  reviewerRcPackageId: 'reviewer',
  experimentRcPackageId: 'experiment',
  discount20RcPackageId: 'discount20',
};
export const searchParamResetQueryCache = 'resetQueryCache';

export const userQuotes = [
  {
    text: 'I highly recommend this app. It guides me through different steps of journaling and helps me do self-reflection exercises. I feel helped and relieved every time I finish my daily journaling.',
    author: 'Pierre C.',
  },
  {
    text: 'CBT is an amazing form of therapy, and this hits the nail on the head, allowing you to see and acknowledge your own ‘thought traps’ as they’re happening. It’s an amazing way to change your mindset and your life.',
    author: 'Kayla J.',
  },
  {
    text: 'Unstuck is perfect and gently guides you though techniques to address whatever issue you’re facing. I find a lot of apps have a bunch of flashy gimmicks that distract, but Unstuck is simple and gets the fundamentals right.',
    author: 'Tom J.',
  },
  {
    text: 'This app is really helpful! Hats off to the creative and thought-provoking prompts it gives while journaling, they really help to change one’s perceptions and reduce stress!',
    author: 'P.P.',
  },
  {
    text: 'Thank you for developing something like this! It takes all the great aspects that I have learned in therapy and gives it a practical way to use it everyday!',
    author: 'J.A.',
  },
  {
    text: 'Great journal app for noting down your feelings and thoughts with anxiety. It helps you dial down your anxiety so you can cope.',
    author: 'Steve C.',
  },
  {
    text: 'Would definitely recommend as an accompanying tool for people currently doing CBT with a therapist, interested in learning about CBT, or trying to keep up good habits after finishing therapy. ',
    author: 'Mike G.',
  },
  {
    text: 'I really enjoy using this app. The mental health assessment feature and the journaling helps to reflect on how I’ve been doing mentally as of recently. I’m looking forward to using this app in the future and recommend that others try it out!',
    author: 'S. Andrew',
  },
  {
    text: 'Unbelievably fantastic app. Simple, tidy, reliable. Directly follows methods used by my therapist. Superb hold-over between sessions and is great for those who do not see a therapist either.',
    author: 'Doug O.',
  },
];
